.portfolioTitleImageCard {
    border-radius: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
}

.portfolioTitleImage {
    float: left;
    aspect-ratio: 1 / 1;
    width: 100%;
    height: 100%;
    object-fit: cover;

    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.portfolioTitleImageOverlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #2c2c2c;
}

.portfolioTitleImageText {
    color: white;
    font-size: 25px;
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    transition: .5s ease;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

@media (hover: hover) {
    .portfolioTitleImageContainer:hover .portfolioTitleImageOverlay {
        opacity: 0.8;
    }

    .portfolioTitleImageContainer:hover .portfolioTitleImageText {
        opacity: 1;
    }

    .portfolioTitleImage:hover {
        -webkit-filter: blur(3px);
    }
}