:root {
    --white: #ecf0f2;
    --blueish-white: #f8fafc;
    --gray: #dee4f0;
    --orange: #e74c3c;
    --purple: #78378c;
    --blue: #3c91e7;
    --shadow: 0 30px 40px 0 rgba(16, 36, 94, .2);
    --rounded-corners: 4px;
    --rounded-corners-large: 8px;
}

.contactIcon {
    color: darkslategrey;
}

.contactGrid {
    border: 0px solid black
}

.contaxtGridRow {
    border: 0px solid black
}

.contactGridRowPicture {
    display: flex;
    align-items: center;
    justify-content: center;

}

.contactGridRowText {
    display: flex;
    align-items: center;
    justify-content: center;


    border: 0px solid black
}

.contactGridRowIconMail {
    display: flex;
    align-items: center;
    justify-content: center;


    border: 0px solid black
}

.contactGridCol {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    border: 0px solid black
}

.contactGridColText {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    font-size: 15px;

    border: 0px solid darkolivegreen
}


.contactMail {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    font-size: 17px;
    font-weight: bold;
    color: darkslategrey;

    border: 0px solid black
}

.Example__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 0;
    padding: 10px;
}

.Example__container__document {
    margin: 1em 0;
    max-width: calc(100%);
    display: flex;
    justify-content: center;
}

.Example__container__document .react-pdf__Document {
    max-width: calc(100% - 2em);
    display: flex;
    flex-direction: row;
    align-items: center;
}

.Example__container__document .react-pdf__Page {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    margin: 0.5em;
}

.Example__container__document .react-pdf__Page canvas {
    max-width: 100%;
    height: auto !important;
}

.Example__container__document .react-pdf__message {
    padding: 20px;
    color: white;
}

.react-pdf__Page__textContent {
    display: none;
}

.react-pdf__Document:hover .page-controls {
    opacity: 1;
}

.react-pdf__Page__annotations {
    display: none;
}

.page-controls {
    position: absolute;
    bottom: 18%;
    left: 50%;
    background: white;
    display: flex;
    box-shadow: var(--shadow);
    border-radius: var(--rounded-corners);
    opacity: 0;
    transform: translate(-50%);
    transition: opacity ease-in-out .2s;
}


.page-controls span {
    font: inherit;
    font-size: .8em;
    padding: 0 0.5em;

    display: flex;
    justify-content: center;
    align-items: center;
}

.page-controls button:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.page-controls button {
    width: 44px;
    height: 35px;
    background: white;
    border: 0;
    color: black !important;
    font-size: .8em;
    border-radius: var(--rounded-corners)
}

.page-controls button:enabled:hover,
.page-controls button:enabled:focus {
    background-color: #e6e6e6
}

.page-controls button:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.page-controls button:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}