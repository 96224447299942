.smallItemStylesLoading {
    cursor: pointer;
    object-fit: cover;
    height: 100%;
    width: 100%;
    aspect-ratio: 1 / 1;

    filter: blur(5px);
    clip-path: inset(0);
  }

  .smallItemStylesLoaded {
    cursor: pointer;
    object-fit: cover;
    height: 100%;
    width: 100%;
    aspect-ratio: 1 / 1;

    filter: blur(0px);
    transition: filter 0.5s linear;
  }