  .portfolio-grid {
    padding-right: 0px;
    padding-left: 0px;
    padding-top: 15px;
    padding-bottom: 0px;
  }

  .portfolio-col {
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 15px !important;
  }


@media (min-width: 992px) {
  .portfolio-grid {
    padding-right: 7px;
    padding-left: 7px;
    padding-top: 7px;
    padding-bottom: 7px;
  }

  .portfolio-col {
    padding-left: 7px !important;
    padding-right: 7px !important;
    padding-top: 7px !important;
    padding-bottom: 7px !important;
  }
}

