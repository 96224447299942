.GalleryThumbImage {
  float: left;
  aspect-ratio: 1 / 1;
  width: 100%;
  height: 100%;
  object-fit: cover;

  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.smallItemStyles {
  cursor: pointer;
  object-fit: cover;
  height: 100%;
  width: 100%;
  aspect-ratio: 1 / 1;
}

.videoContainer {
  position: relative;
}

.playBtn {
  position: absolute;
  width: 50px;
  height: 50px;
  left: 50%;
  top: 50%;
  margin-left: -25px;
  /*half of the width */
  margin-top: -25px;
  /*half of the height */
}

.playBtn:hover {
  position: absolute;
  width: 79px;
  height: 79px;
  left: 50%;
  top: 50%;
  margin-left: -35px;
  /*half of the width */
  margin-top: -35px;
  /*half of the height */
}