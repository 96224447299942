ion-modal#video-modal {
    --width: 80%;
    --height: auto;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

.videoContent {
    width: 100% !important;
    height: auto !important;
}

.wrapper {
    width: 100%;
    height: 100%;
    margin: none;
    padding: none;
    background-color: black;
}